/*--------------------------------------------------
Template CSS used for items in WebsiteTemplate repo
--------------------------------------------------*/

/*Main image on front page*/
.banner-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
}
  
.banner-image {
    /* background-image: linear-gradient(10deg, #00000088 15%, #ffffff44 40%), url('../Images/ExampleImage'); */
    width: 100%;
    height: 100em;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-height: 92vh;
}

.banner-text {
    position: absolute;
    font-size: xxx-large;
    font-weight: 700;
    padding: 1em;
    bottom: 0em;
    text-shadow: 1px 1px 8px black;
}


/*Over-rides Bootstrap classes for custom nav bar*/
.navbar {
    z-index: 10 !important;
    border-bottom: 2px solid #00000088;
    font-weight: bold;
    padding: 1em !important;
    width: 100vw;
}
.navbar-nav {
    align-items: center;
}
.nav-bg .purple {
    height: 100% !important;
}
.nav-link {
    color: #eeeeee !important;
}
.navbar-brand {
    color: #eeeeee !important;
}
.navbar-toggler {
    background-color: #eeeeee !important;
}
.nav-dropdown {
    font-family: "Jockey One";
    color: var(--Theme-1-Lightest-Grey, #F9FAFF);
    text-align: center;
    font-size: 2.5em;
    align-self: center;
}
/*mobile navbar */
.navbar-toggler {
    background-color: transparent !important;
    border: none !important;
    margin: 15px;
  }
  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus{
    outline: 3px solid #FFF !important;
    box-shadow: none !important;
  }
  .navbar-toggler-icon{
    background-image: url('../Images/navbar-toggle-icon.svg') !important;
  }
.loggedOut-dropdown .dropdown-menu {
    left: -200% !important;
}
.loggedIn-dropdown .dropdown-menu{
    left: 0% !important;
}
.nav-img {
    width: 7.5vh;
    height: 7.5vh;
    min-width: 50px;
    min-height: 50px;
    margin-right: 1%;
    border-radius: 50%;
    overflow: hidden;
}
.nav-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.offcanvas-body {
    background-color: black !important;
}
.offcanvas-header {
    background-color: black !important;
    color: #eeeeee
}
.btn-close {
    background-color: white !important;
}

.primary-container {
    width: 100%;
    margin: auto;
    min-height: 95vh;
}
.white-bg {
    background-color: white;
    color:black
}
.black-bg {
    background-color: black;
    color: white;
}
.grey-bg {
    background-color: rgb(101, 104, 107);
    color: white;
}
.content-container {
    margin: auto;
    padding: 3em 2em;
    width: 80%;
    display: flex;
}
.flex-container{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.flex-column {
    flex: 1;
    padding: 1em;
    margin: 5px;
    min-width: 10em;
    height: 60vh;
    border-radius: 5px;
}

.center {
    text-align: center;
    margin: auto;
    justify-content: center;
}

.grid-col-6 {
    grid-column: 6;
}

.page-height {
    height: 100vh;
}

.page-width {
    width: 100vw;
}

.page {
    width: 100vw;
    height: 115vh;
}

.full {
    width: 100%;
    height: 100%;
}
.full.full-travelogue{
    background-color: #FFE7DC;
    display: flex; 
    position: relative;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}
