/*--------------------------------------------------
CSS for Quest/Activity related pages and components
--------------------------------------------------*/
.activity-image {
    margin: 10px;
    width: fit-content;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.fullscreen-button  {
    background-color: rgba(208,208,208,0.5) !important;
    border-radius: 2px;
    height: 50px;
    width: 50px;
    position: absolute;
    padding: 0px !important;
}

.fullscreen-button:hover {
    background-color: rgba(208,208,208,1) !important;
}

.fullscreen-container {
    position: fixed;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    padding: 5%;
    top:0;
}

.close-fullscreen-button {
    position: fixed;
    right: 2%;
    top: 2%;
    padding: 10px;
    border-radius: 15px;
    border: 0;
    background-color: rgba(0,0,0,0);
}

.arrow-button {
    position: fixed;
    display: flex;
    align-self: center;
    background-color: rgba(0,0,0,0);
    border: none;
    padding: 20px;
}

.arrow-button:hover {
    background-color: rgba(208,208,208,0.5) !important;
}

.left-arrow-button {
    left: 0;
}

.right-arrow-button {
    right: 0;
}

.close-fullscreen-button:hover {
    background-color: rgba(208,208,208, 0.5);
}