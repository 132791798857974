/*--------------------------------------------------
Generic CSS for initial components
--------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jockey+One&display=swap');
:root {
  --site-background: #2e2e2e;
  --text-color: #e4e4e4;

  --button-color: #d0d0d0;
  --button-text-color: #292929;
  --button-hover-color: #ababab;
  --border-button-text-color: #e6e6e6;

  --container-text-color: #111111;
  --container-color: #f3f3f3;
  --border-container-text-color: #e4e4e4;
  --border-container-color: #e4e4e4;

  --link-color: #fb9d9d;

  --white: #FFFFFF;
  --black: #000000;
  --Theme-1-Black: #212022;
  --main-font: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  --header-font: "Rowdies", sans-serif;
  --secondary-header-font: "Jockey One", sans-serif;


  --main-background: #FFE7DC;
  --panel-shadow: #D69A87;
  --panel-background: #B27561;
  --purple: #593F75;
  --dark-purple: #3C2454;

  --dark-blue: #41598E;

  --page-width: 87.5%;

  --width-margin: 12.5%;

  --order-number: #7A5A4B;
  --order-circle: #E6CABC;
  --order-line: #EFD6CA;

  overflow-x: hidden !important;

}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hidden {
  display: none !important;
}

.overflow-x-clip {
  overflow-x: clip !important;
}

#root {
  background-color: var(--site-background);
  color: var(--text-color);
  height: auto;
  min-height: 100vh;
}

.main-container {
  width: 75%;
  margin: auto;
  background-color: transparent;
}

.main-container .container {
  text-align: center;
  margin: 1em auto;
  width: 100%;
  padding: 1em;
  border-radius: 10px;
  background-color: var(--container-color);
  color: var(--container-text-color);
}

.main-container .border-container {
  text-align: center;
  width: 100%;
  margin: 1em auto;
  padding: 1em;
  border-radius: 10px;
  border: solid 3px var(--border-container-color);
  color: var(--border-container-text-color);
}

.main-container button {
  cursor: pointer;
  padding: .5em 1em;
  font-size: 16px;
  border: none;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border-radius: 10px;
  font-weight: 600;
  margin: .5em;
}

.main-container button:hover {
  background-color: var(--button-hover-color);
}

.main-container .border-button {
  cursor: pointer;
  padding: .35em .5em;
  font-size: 16px;
  background-color: transparent;
  border: solid 3px var(--button-color);
  color: var(--border-button-text-color);
  border-radius: 10px;
  font-weight: 600;
  margin: .5em;
}
.main-container .border-button:hover {
  background-color: var(--button-hover-color);
}

.main-container a {
  /* color: var(--link-color); */
  text-decoration: none;
}

.main-container a:hover {
  text-decoration: underline;
}

.main-container input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: block;
}

.main-container .center {
  justify-content: center;
  margin: auto;
  text-align: center;
}

.main-container .flex-container {
  display: flex;
  flex-wrap: wrap;
}


.loading-container {
  position: fixed; 
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #47474741;
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff; 
  text-shadow: 4px 2px 6px black;
}

.content-container .sign-in {
  background-color: rgb(255, 255, 255);
  box-shadow:  2px 2px 10px rgb(24, 24, 24);
  border-radius: 3px;
  height: 100%;
  max-width: 35%;
}
.sign-in-bg {
  position: absolute;
  /* background-image: linear-gradient(-45deg, #00000088 30%, #ffffff44 90%), url('../Images/ExampleImage'); */
  width: 100%;
  height: auto;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sign-in input {
  display: block;
  margin: 1em auto;
  padding: 5px;
  border-radius: 5px;
}
.sign-in h2 {
  margin-bottom: 2.5em;
}
.sign-in button {
  padding: .5em .8em;
  border: none;
  border-radius: 10px;
  margin-top: 2em;
  margin: 4.5em .5em 0 .5em;
}


/* || Register/Login CSS Classes */

.grid-display {
  display: grid;
  /* grid-template-columns: min-content; */
  height: 100%;
  width: 100%;
}

.register-container {
  display: block;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 2em;
  margin: 2% 5% !important;
  width: 40%;
  height: 90%;
  padding: 2%;
}

.register-container .grid {
  display: grid;
  grid-template-rows: 1fr 3fr 1fr;
  height: 100%;
  width: 100%;
}

.register-container .section {
  width: 100%;
  height: 100%;
  display: inline;
  flex-flow: wrap;
  justify-content: space-between;
  margin-bottom: 1%;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.register-container .section2 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin-top: 2%;
}

.register-container h1 {
  font-family: var(--header-font);
  font-size: 4rem;
  font-weight: bold;
  margin: 0% auto;
  color: var(--dark-purple);
  width: 100%;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* margin-bottom: 10%; */
}

.register-container .back {
  font-family: var(--main-font);
  font-size: 2em;
  font-weight: bold;
  color: var(--purple);
  margin-bottom: 5%;
  cursor: pointer;
  justify-self: flex-start;
}

.register-container .middleText {
  font-family: var(--secondary-header-font);
  font-size: 2.5em;
  font-weight: bold;
  margin: 0% auto;
  color: rgb(0, 0, 0);
  width: 100%;
  text-align: center;
}

.register-container .middleText-small {
  font-family: var(--secondary-header-font);
  font-size: 1.75em;
  font-weight: bold;
  margin: auto;
  color: rgb(0, 0, 0);
  width: 100%;
  text-align: center;
}

.register-container .full-input {
  font-family: var(--main-font);
  border-radius: 1em;
  border: 3px var(--purple) solid;
  background-color: rgba(255, 255, 255, 0);
  color: rgb(0, 0, 0) !important;
  padding: 2%;
  height: 90%;
  width: 100%;
  margin: 2% 0%;
  font-size: 2em;
}

.register-container .half-input {
  font-family: var(--main-font);
  border-radius: 1em;
  border: 3px var(--purple) solid;
  background-color: rgba(255, 255, 255, 0);
  color: rgb(0, 0, 0) !important;
  padding: 2%;
  height: 90%;
  width: 48%;
  margin: 2% 0%;
  font-size: 2em;
}

.register-button {
  font-family: var(--main-font);
  font-size: 3em;
  border-radius: 1em;
  border: none;
  background-color: var(--purple);
  color: white !important;
  height: 80%;
  width: 100%;
  margin: 5% 0%;
}

.register-container img {
  height: 12rem;
  width: auto;
  border-radius: 50%;
  margin: 0% auto;
  object-fit: cover;
}

.small-gap {
  align-content: baseline;
}

.span-all {
  width: 100%;
}

.span-half {
  width: 50%;
  height: 40%;
  margin: 0% auto;
}

.small-button {
  font-family: var(--header-font);
  font-size: 1.75em;
  border-radius: 1em;
  border: none;
  background-color: var(--purple);
  color: white !important;
  height: 100%;
  width: 100%;
  margin: 5% 0%;
}
@media (max-height: 700px) {
  .small-button{
    font-size: 1.5em;
  }
}

.selection-button {
  font-family: var(--header-font);
  font-size: 2rem;
  font-weight: 500;
  border-radius: .5em;
  border: none;
  background-color: var(--dark-blue);
  color: white !important;
  height: 25%;
  width: 100%;
  margin: 4% 0%;
}
@media (max-height: 600px){
  .selection-button{
    margin: 2% 0;
  }
}

.register-button:disabled {
  opacity: .8;
  background-color: grey;
}

.boise-background {
  background-image: url("../Images/Login_Backdrop.png");
  background-size: cover;
}

.register-container * .content {
  display: block;
  color: black;
  font-size: 2em;
  font-weight: 400;
  font-family: var(--main-font);
  margin: auto;
}

.register-container * .content span{
  color: rgb(143, 14, 150);
  font-size: 1.5em;
}

.reset-password-button {
  margin-top: 5%;
  color: var(--Theme-1-Black, #212022);
  font-family: var(--secondary-header-font);
  font-size: 1.5em;
  text-decoration: none;
}
.click-here{
  color: var(--purple);
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .register-container {
    width: 90%;
    height: 95%;
  }

  .register-container h1{
    font-size: 3rem;
  }
}

/* || Dashboard CSS Classes */

.max-height-screen {
  max-height: 100vh;
}

.sectionHeader {
  margin-left: 5%;
  font-family: var(--header-font);
  color: var(--purple);
  font-weight: bold;
  font-size: 3em;
  align-items: center;
  display: flex;
}

.sectionHeader > .text-black{
  padding: 0 1%;
}

.sectionHeader-middle {
  text-align: center;
  width: 100%;
  font-family: var(--header-font);
  color: var(--purple);
  font-weight: bold;
  font-size: 4em;
}

.grid-col-1 {
  grid-column: 1 span;
}

.grid-col-2 {
  grid-column: 2 span;
}

.grid-col-12 {
  grid-column: 12 span;
}

.grid-row-1 {
  grid-row: 1 span;
}

.grid-row-2 {
  grid-row: 2 span;
}

.sidebar-inside {
  position: sticky;
}

.dashboard-nav{
  height: 100%;
  padding: 0.5%;
  width: 15%;
  position: fixed;
  left: 0;
  top: 0;
  background-image: url(../Images/Backdrop-Transparent.png),  linear-gradient(180deg, #A6D0F6 38.5%, #FFF 100%);
  background-repeat: no-repeat;
  background-size: 300%;
  background-position: bottom;
  overflow-y: hidden;
  z-index: 100 !important;
}

.home-splash {
  background-image: url(../Images/IdaGem_Splash.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.dashboard-nav .nav{
  padding-top: 20%;
  height: 100%;
  width: 100%;
  display: inline-flex;
  text-align: center;
  align-content: baseline;
  justify-content: center;
  font-size: 1.7em;
  font-family: Rowdies;
  font-weight: bold;
}

.dashboard-nav .header{
  margin-top: 3%;
  text-align: center;
  align-content: center;
  width: 100%;
  display: grid;
  font-size: 4.5em;
  line-height: 114.242%;
  font-family: var(--header-font);
  font-weight: bold;
  color: var(--Theme-1-Dark-Blue, #41598E);
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}

.dashboard-nav > * > a{
  padding: 10%;
  width: 100%;
  margin-top: 15%;
  margin-left: 5%;
  margin-right: 5%;
  color: var(--white);
  background-color: purple;
  border-radius: .5em;
  text-decoration: none !important;
}

.dashboard-nav > * > a.active{
  color: var(--white);
  background-color: var(--purple);
}

.dashboard-nav > * > a:hover{
  transform: scale(1.05);
  cursor: pointer;
}

@media (max-width: 1274px){
  .dashboard-nav .header{
    font-size: 3em;
  }
  .dashboard-nav .nav{
    font-size: 1em;
  }
}
@media (max-width: 780px){
  .dashboard-nav .header{
    font-size: 2.5em;
  }
  .dashboard-nav .nav{
    font-size: .5em;
  }
}

.classlist {
  grid-template-rows: 10% auto;
  grid-template-columns: 1fr;
  display: grid;
  /* align-content: baseline; */
  padding: 5% 4%;
  padding-top: 1%;
  width: var(--page-width);
  min-height: 100%;
  margin-left: var(--width-margin);
  background-color: var(--main-background);
}

.classlist .classContainer {
  border-radius: .5em;
  margin: 1% 5%;
  background-color: var(--white);
  height: fit-content;
  /* width: 100%; */
  -webkit-box-shadow: 15px 15px 0px -1px var(--panel-shadow);
}

.classlist .classCol {
  justify-self: center;
  padding: 1%;
  /* background-color: var(--white); */
  color: black;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: .30fr 0.7fr 0.5fr 3.5fr;
  border-radius: 0.5em;
  justify-content: center;
}

.classCol .imageContainer {
  height: 65px;
  width: 65px;
  margin: auto;
}

.classCol img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.classCol .classSection {
  text-align: left;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 20% auto;
}

.classEditCol {
  padding: 0.5%;
}

.classlist .links {
  margin: auto 0%;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.links .linkElement {
  margin: 0% 5%;
}

.classlist .newClass {
  padding: 1% 0%;
  border-radius: 0em 0em 0.5em 0.5em;
  justify-content: flex-start;
  background-color: rgb(143, 143, 143);
  display: inline-flex;
  width: 100%;
}

.bold {
  font-weight: bold;
}

.font-m {
  font-size: 1.5em;
}

.font-lg {
  font-size: 2em;
}

.font-xl {
  font-size: 3.5em;
}

.font-bold {
  font-weight: bold;
}

.classlist-link {
  color: rgb(27, 158, 201);
  cursor: pointer;
}

.classlist-link-small {
  font-size: 1.2em;
  font-weight: bold;
  margin-left: 1%;
  color: rgb(27, 158, 201);
  cursor: pointer;
}

.refresh-arrows {
  cursor: pointer;
}

.classlist .addClassTitle {
  margin: auto 0%;
}

.classlist .addClass {
  margin: auto 5%;
  height: 100%;
  cursor: pointer;
}

.approvalRow {
  display: flex;
  flex-direction: column;
  color: var(--black);
  padding: 1%;
  padding-right: 2%;
}

.approvalRowHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.approvalContent {
  padding: 1%;
  font-size: 1.5em;
}

button.approvalButtonApprove {
  font-size: 1.5em;
  background-color: var(--purple);
  color: var(--Theme-1-Lightest-Grey, #F9FAFF);
  font-family: var(--header-font);
  font-weight: 400;
}

button.approvalButtonDeny {
  font-size: 1.5em;
  background-color: var(--Other-Warning-Red, #FCBABA);
  color: var(--Other-Warning-Dark-Red, #BF2D2D);
  font-family: var(--header-font);
  font-weight: 400;
}

.pdf {
  margin-top: 2%;
  width: 100%;
  height: 100vh;
}

#student-id{
  color: #666569;
}

.smaller-student-text{
  font-size: 18px;
}

/* || Student View CSS Classes */

.studentView {
  grid-template-rows: 10%;
  grid-template-columns: 1fr;
  display: grid;
  align-content: baseline;
  padding: 4% 4%;
  width: var(--page-width);
  min-height: 100vh;
  margin-left: var(--width-margin);
  background-color: var(--main-background);
}

.TravelogueView {
  grid-template-rows: 10%;
  grid-template-columns: 1fr;
  display: grid;
  align-content: baseline;
  padding-top: 0%;
  padding-right: 0.5%;
  padding-left: 3%;
  width: var(--page-width);
  min-height: 100vh;
  margin-left: var(--width-margin);
  background-color: var(--main-background);
}

.TravelogueView .studentContainer {
  border-radius: .5em;
  margin: 5% 5%;
  background-color: var(--white);
  height: fit-content;
  /* width: 100%; */
  -webkit-box-shadow: 15px 15px 0px -1px var(--panel-shadow);
}

.TravelogueView .studentRow {
  justify-self: center;
  padding: 1%;
  /* background-color: var(--white); */
  color: black;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: .33fr 1fr 3fr;
  border-radius: 0.5em;
  justify-content: center;
}

.TravelogueView .links {
  margin: auto 0%;
  display: flex;
  justify-content: end;
}

.TravelogueView .headerRow {
  width: auto;
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
}

.studentView .studentRow {
  justify-self: center;
  padding: 1%;
  /* background-color: var(--white); */
  color: black;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: .33fr 1fr 3fr;
  border-radius: 0.5em;
  justify-content: center;
}

.studentRow .studentSection {
  text-align: left;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 20% auto;
}

.studentRow .imageContainer {
  height: 65px;
  width: 65px;
  margin: auto;
}

.studentRow img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.studentView .studentContainer {
  border-radius: .5em;
  margin: 5% 5%;
  background-color: var(--white);
  height: fit-content;
  /* width: 100%; */
  -webkit-box-shadow: 15px 15px 0px -1px var(--panel-shadow);
}

.studentView .links {
  margin: auto 0%;
  display: flex;
  justify-content: end;
}

.links .linkElement {
  margin: 0% 5%;
}

.bannerBackground {
  top: 0%;
  display: grid;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #00000088;
  z-index: 2;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease-in-out;
}

.bannerBackground.hidden {
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.bannerPopup {
  display: grid;
  grid-template-rows: 25% 25% 25% 25%; 
  position: absolute;
  justify-self: center;
  align-self: center;
  height: 60%;
  width: 50%;
  background-color: beige;
  border-radius: 0.5em;
}

.bannerPopup .header {
  padding: 1%;
  justify-self: center;
  font-size: 2.6vi;
  color: black;
  font-weight: bold;
  font-family: var(--main-font);
}

.bannerPopup .content {
  width: 100%;
  height: 100%;
  padding: 1%;
  margin: 1%;
  text-align: center;
  justify-self: center;
  font-size: 1.2vi;
  color: black;
  font-weight: bold;
  font-family: var(--main-font);
}

.bannerPopup .buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
}

.bannerPopup button {
  border-radius: 0.5em;
  width: 90%;
  margin: auto;
  border: none;
  font-family: var(--main-font);
  font-weight: bold;
  font-size: 2vi;
  color: white;
}

.bannerPopup img {
  width: 15%;
  justify-self: center;
  justify-content: center;
  margin-bottom: 2%;
}

.bannerPopup label{
  color:black;
}

.text-red {
  color: red !important;
}

.text-black {
  color: var(--black) !important
}

.text-purple {
  color: var(--purple) !important
}

.red {
  background-color: #BF2D2D !important;
}

.purple {
  background-color: var(--purple) !important;
}

.white {
  background-color: var(--white) !important;
}

.classEditBtn {
  background-color: var(--dark-blue) !important;
}

.viewPDFBtn {
  background-color: var(--purple) !important;
  width: 80%;
  margin: 2% 10% !important;
  font-weight: bold !important;
  font-size: 1.2vi !important;
  color: white !important;
}

.classImgEdit {
  width: 100%;
  padding: 2%;
}


/* || Single Student View CSS Classes */


.singleStudentView {
  min-height: 100vh;
  width: 85% !important;
  margin-left: 15% !important;
  margin-left: var(--width-margin);
  padding-bottom: 2%;
  background-color: var(--main-background);
  padding-right: 0% !important;
  padding-left: 0% !important;
}

.singleStudentView .row {
  width: 100%;
  margin-right: 0%;
  margin-left: 0%;
}

.singleStudentView .student-view-header {
  width: 100%;
  margin-bottom: 3%;
  color: var(--Theme-1-Black, #212022);
  font-family: "Jockey One";
}

.class-name {
  font-family: "Jockey One";
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Theme-1-Peach, #D69A87);
  font-size: 2em;
  text-align: center;
}

.class-name a {
  text-decoration: none !important;
  color: #000000 !important;
}

.singleStudentView .student-dropdown {
  background: var(--Theme-1-Peach, #D69A87);
  color: var(--Theme-1-Black, #212022);
  display: flex;
  align-items: center;
  justify-content: center;
}

.singleStudentView .student-dropdown .dropdown-toggle {
  background: none !important;
  font-size: 1.5em;
}

.singleStudentView .student-name {
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: var(--Theme-1-Dark-Purple, #3C2454);
  font-family: Rowdies;
}

.singleStudentView .student-name h1 { 
  font-size: 4em;
}

.singleStudentView .student-info-row {
  padding: 0% 5%;
  margin-bottom: 2%;
  color: var(--Theme-1-Black, #212022);
}

.singleStudentView .student-section {
  padding: 2%;
  width: 43%;
  position: relative;
  background-color: var(--white);
  border-radius: 1vi;
  -webkit-box-shadow: 20px 20px 0px -1px var(--panel-shadow);
}

.student-profile {
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.student-profile img {
  width: 7vw !important;
  height: 7vw !important;
  margin-right: 2%;
}

.student-portfolio {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.student-portfolio a {
  font-size: calc(.3rem + 1.5vw);
  margin-bottom: 4% !important;
}

.singleStudentView .student-section img { 
  width: 27%;
  height: 9vh;
  border-radius: 50%;
}

.singleStudentView .student-section .username {
  font-family: "Jockey One";
  font-size:calc(1rem + 1.2vw) !important;
  color: #000000;
}

.singleStudentView .student-section .student-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  color: #000000;
}

.student-info h2 {
  font-size: calc(.7rem + 1.5vw) !important;
}

.student-info p {
  font-size: calc(.3rem + 1.5vw) !important;
}

.singleStudentView .quest-tracker {
  font-family: Rowdies;
  border-radius: 1vi;
  background: var(--Theme-1-Peach, #D69A87);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.singleStudentView .quest-tracker h1 { 
  font-size: calc(2rem + 1.5vw) !important;
  text-align: center;
  color: var(--Theme-1-Black, #212022);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0%;
}

.singleStudentView .student-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--Theme-1-Blue, #4B86BD);
  font-family: Rowdies;
  font-size: 2em;
  font-weight: 400;
}

.singleStudentView .student-links a { 
  margin-bottom: 3% !important;
  cursor: pointer;
}

.singleStudentView .quest-progress {
  width: 48%;
  margin-left: 9%;
  padding: 1.5%;
  font-family: Rowdies;
  text-align: center;
  border-radius: 8px;
  background: var(--Theme-1-Dark-Peach, #B27561);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.singleStudentView .quest-progress h1 { 
  font-size: calc(1.5rem + 1.5vw);
  margin-bottom: 2%;
}

.singleStudentView .quest-progress .current-quest { 
  padding: 2%;
  margin-bottom: 4%;
  border-radius: 8px;
  background: var(--Theme-1-Light-Peach, #FFE7DC);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.singleStudentView .quest-progress .current-quest p { 
  margin-top: 5%;
}

.circular-progress-bar {
  color: var(--Theme-1-Lightest-Grey, #F9FAFF);
  margin-top: 35%;
  font-size: 1.5vi;
  margin-left: 30%;
}

.CircularProgressbar {
  background-color: #8688D8;
  border-radius: 50%;
  margin-left: 15%;
}

.singleStudentView .quest-popsicles {
  height: fit-content !important;
}

.singleStudentView .quest-popsicle {
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #FFFFFF;
}

.singleStudentView .quest-popsicle img {
  object-fit: contain;
  width: 40%;
  margin-bottom: 5%; 
}


/* || Create Class Popup */

.createClass {
  display: grid;
  position: absolute;
  justify-self: center;
  align-self: center;
  height: 60%;
  width: 60%;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5em;
  -webkit-box-shadow: 15px 15px 0px -1px var(--panel-shadow);
}

.createClass h1 {
  text-align: center;
  font-family: var(--header-font);
  color: var(--purple);
  font-size: 4em;
  align-self: center;
}

.createClass .inputRow {
  margin: auto;
  display: grid;
  width: 90%;
  grid-template-rows: 40% auto;
  border: 4px solid #B27561;
  border-radius: 4px;
  background-color: var(--main-background);
}

.createClass .inputRow label {
  font-family: Rowdies;
  color: #593F75;
}

.confirmInputRow {
  margin: 0% 4%;
  width: 90%;
  border: 4px solid #B27561;
  border-radius: 4px;
  background-color: var(--main-background);
}

.confirmInputRow label {
  font-family: Rowdies;
  color: #593F75;
  margin-left: 2%;
}

.confirmInputRow input {
  width: 90%;
  height: 50%;
  background-color: #00000000;
  border: none;
  font-size: calc(0.3em + 1.2vw);
  margin-left: 2%;
  margin-top: 2%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

.confirmInputRow input:focus { 
  outline: none;
  border: none;
}

.createClass .inputRow ::placeholder, .createClass .inputRow input, .createClass select {
  font-family: "Jockey One" !important;
  color: #000000 !important;
  font-weight: 500;
}

.createClass .inputGrid {
  display: flex;
  align-items: center;
}

.createClass .inputSmall {
  margin: auto !important;
  width: 40% !important;
  height: 70%;
}

.createClass .buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.createClass button {
  border-radius: 0.5em;
  width: 90%;
  margin: auto;
  border: none;
  font-family: var(--main-font);
  font-weight: bold;
  font-size: 2em;
  color: var(--white);
}

.createClass label{
  color:rgb(255, 255, 255);
  padding: 1%;
  font-family: var(--main-font);
  font-weight: bold;
  font-size: calc(0.5em + 1.2vw);
}

.createClass input, .createClass select{
  width: 100%;
  height: auto;
  background-color: #00000000;
  border: none;
  font-size: calc(0.3em + 1.2vw);
  padding-top: 0px;
  padding-bottom: 0px;
}

.createClass input:focus, .createClass select:focus{
  outline: none;
  border: none;
}

.createClass input::placeholder {
  color:rgba(255, 255, 255, 0.574);
  padding: 1%;
  font-family: var(--main-font);
  font-weight: bold;
  font-size: calc(0.3em + 1.2vw);
}

.createClass input::file-selector-button {
  background-color: transparent;
  border: none;
  font-size: calc(0.3em + 1.2vw);
  font-weight: 500;
  padding: 0px;
}

.createClass select {

  padding-left: 3%;
  padding-right: 2%;
}


/* || QUEST DASHBOARD */

.questsContainer {
  display: grid;
  grid-template-columns: 2.5fr 1.2fr;
  grid-template-rows: 1fr;
  gap: 2%;
  /* align-content: center; */
  /* padding: 5% 3%; */
  width: 100%;
  height: 100%;
  /* margin-left: 10%; */
  background-color: var(--main-background);
}

.questsContainer .quests {
  padding: 2%;
  background-color: var(--white);
  color: black;
  width: 90%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  margin: auto;
  border-radius: 0.5em;
  justify-content: start;
  align-self: end;

  -webkit-box-shadow: 15px 15px 0px -1px var(--panel-shadow);
}

.questsContainer .quests div {
  background-color: var(--panel-background);
  flex-grow: 1;
  flex-basis: 29%;
  height: auto;
  width: 50px;
  margin: 2%;
  padding: 2%;
  border-radius: .5em;
  display: grid;
  text-align: center;
  align-content: center;
  font-family: var(--main-font);
  color: var(--white);
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}

.questsContainer .quests div:hover { 
  transform: scale(1.05);
  padding-left: 1%;
  border: 5px solid var(--purple);
}

@media (max-width: 900px){
  .questsContainer .quests div{
    font-size: .8rem;
  }
}

.animateFromRight {
  animation: .75s ease-out 0s 1 slideInFromRight;
}

.questsContainer .questInfo {
  background-color: var(--white);
  color: black;
  width: 90%;
  height: 100%;
  padding: 5%;
  display: grid;
  grid-template-rows: 15% 1fr;
  grid-template-columns: 1fr;
  margin: auto;
  border-radius: 0.5em;
  justify-content: start;
  align-self: end;

  -webkit-box-shadow: 15px 15px 0px -1px var(--panel-shadow);
}


.questsContainer .questInfo h1 {
  text-align: center;
  align-self: center;
  font-family: var(--header-font);
  font-weight: bold;
}

.questInfo .questContent {
  background-color: var(--panel-background);
  border-radius: .5em;
  height: 95%;
  width: 100%;
  display: grid;
  grid-template-rows: 30% 5% 10% 45% 10%;
  grid-template-columns: 1fr;
  padding: 2%;
}

.quest-list {
  background-color: var(--main-background);
}

.quest-requirements {
  background-color: var(--white);
  color: black;
  width: 76%;
  height: 100%;
  padding: 2%;
  border-radius: 0.5em;
  justify-content: start;
  align-self: end;
  margin-left: 19%;
  -webkit-box-shadow: 15px 15px 0px -1px var(--panel-shadow);
}

.quest-requirements .phase {
  display: flex;
  gap: 4%;
  background-color: var(--panel-background);
  padding: 1%;
  border-radius: .5em;
  margin-bottom: 1%;
  align-items: center;
  width: 100%;
}

.phase-title {
  width: 25%;
}

.quest-requirements h1 {
  font-weight: bold;
}

.quest-requirements h2 { 
  color: var(--white);
  font-weight: bold;
  font-size: 2vi;
}

.quest-requirements h3 { 
  color: var(--white);
  font-weight: bold;
  font-size: 1.5vi;
}

.quest-requirements p { 
  color: var(--white);
  font-weight: bold;
  margin-bottom: 0 !important;
  text-align: center;
  font-size: 0.95vi;
}

.quest-requirements button {
  color: white;
  padding: .5em 1em;
  font-size: 16px;
  border: none;
  background-color: var(--button-color);
  border-radius: 10px;
  font-weight: 600;
  margin: .5em;
}

.sentence-req-container {
  display: flex;
  gap: 2%;
}

.sentence-requirement {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sentence-requirement input {
  width: 50%;
  text-align: center;
}

.questContent h2 {
  text-align: center;
  align-self: end;
  font-family: var(--header-font);
  font-weight: bold;
  font-size: calc(0.2rem + 1.5vw);
}

.questContent h3 {
  text-align: center;
  align-self: baseline;
  font-family: var(--header-font);
  font-weight: bold;
}

.questContent hr {
  width: 75%;
  border-top: 3px solid;
  justify-self: center;
  font-family: var(--header-font);
  font-weight: bold;
  color: black;

}

.questContent .stat {
  padding: 5%;
  color: var(--purple);
  font-size: calc(0.2rem + 1.2vw);
  font-family: var(--header-font);
}

.questContent .stat span {
  color: var(--black);
  font-size: .75em;
  font-weight: 600;
  font-family: var(--main-font);
}

.justify-end {
  justify-self: end;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 35px;
  margin-left: 2%;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--white);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--purple);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--purple);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.inline-flex {
  display: inline-flex;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}




/* || Settings Page */

.settings-view {
  display: grid;
  grid-template-rows: 10% 1.5fr;
  /* align-content: center; */
  padding: 5% 3%;
  width: var(--page-width);
  height: 100vh;
  margin-left: var(--width-margin);
  overflow-y: auto;
  background-color: var(--main-background);
}


.settings-view .panel {
  padding: 2%;
  background-color: var(--white);
  color: black;
  width: 90%;
  height: 100%;
  display: grid;
  /* grid-template-rows: 15% 1fr; */
  grid-template-columns: 1fr 1fr;
  margin: auto;
  border-radius: 0.5em;
  justify-content: start;
  align-self: end;

  -webkit-box-shadow: 15px 15px 0px -1px var(--panel-shadow);
}

.panel .user-left {
  display: grid;
  grid-template-rows: 20%;
}

.panel .user-right {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}
.panel .name-row {
  align-self: center;
  display: grid;
  height: 100%;
  margin-top: 5%;
}

.panel .username{
  font-size: 1.5em;
  font-weight: bold;
}

.panel .name-row .text-input {
  border: none;
  background-color: #eee;
  border-radius: 0.5em;
  width: 90%;
  height: 75px;
  padding: 5%;
  font-size: 2em;
  font-weight: 200;
  font-family: var(--main-font);
}

.text-input:focus {
  outline-width: 0;
}

.panel .input-with-icon {
  display: flex;
  background-color: #eee;
  justify-content: space-around;
  border-radius: 0.5em;
  width: 90%;
  height: 75px;
  padding: 2%;
}


.panel .input-with-icon .field {
  background-color: #00000000 !important;
  border: none;
  width: 100%;
  font-size: 1.5em;
  font-weight: 200;
  font-family: var(--main-font);
}

.field:focus {
  outline-width: 0;
}

.panel .input-with-icon svg {
  margin: auto;
  height: 75%;
  width: 10%;
}

.panel .name-row .full-input {
  border: none;
  background-color: #eee;
  border-radius: 0.5em;
  width: 90%;
  height: 75px;
  padding: 5%;
  font-size: 1.5em;
  font-weight: 200;
  font-family: var(--main-font);
}

.align-end {
  align-self: end;
}

.margin-l-5 {
  margin: 0% 5%;
}

.settings-view .change-pfp {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: .5fr 1fr;
  margin-bottom: 2em;
}

.circle-blue-settings {
  border-radius: 50%;
  /* margin: -10%; */
  width: 150px;
  height: 150px;
  background-color: var(--dark-blue);
  color: var(--white);
  justify-self: flex-start;
  align-self: flex-start;

  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: bold;
  font-size: 2em;
  contain: content;
}

.circle-blue-settings img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* The switch - the box around the slider */
.change-pfp-button {
  position: relative;
  display: grid;
  margin: auto 5%;
  height: 50%;
  width: 75%;
  /* width: 60px;
  height: 35px; */
}
@media (max-width: 1409px){
  .change-pfp-button{
    width: 82%;
  }
}
@media (max-width: 1262px){
  .change-pfp-button{
    height: 60%;
  }
  .change-pfp-button .clickable{
    font-size: 1em;
  }
}
@media (max-width: 905px){
  .change-pfp-button{
    height: 95%;
  }
  .change-pfp-button .clickable{
    font-size: .75em;
  }
}

/* Hide default HTML checkbox */
.change-pfp-button input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.change-pfp-button .clickable {
  position: absolute;
  cursor: pointer;
  border-radius: 0.5em;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: var(--main-font);
  font-weight: bold;
  align-content: center;
  justify-content: center;
  font-size: 1.25em;
}

.change-button {
  width: 50%;
  height: 50px;
  border: none;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 0.5em;
  text-align: center;
  font-family: var(--main-font);
  font-weight: bold;
  align-content: center;
  justify-content: center;
  font-size: 1.25em;
}
@media (max-width: 950px){
  .change-button{
    width: 52%;
  }
}
@media (max-width: 900px){
  .change-button{
    height: 60px;
  }
}
.user-right .buttons {
  display: flex;
  margin: auto;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
.user-right .buttons button {
  height: 35%;
  border: none;
  border-radius: 0.5em;
  width: 40%;
  margin: auto;
  color: var(--white);
  font-family: var(--main-font);
  font-weight: bold;
  font-size: 1.25em;
}

/* || Reset Password Page */

.reset-password {
  padding: 5% 0%;
  background-color: var(--main-background);
}

.reset-password .panel {
  padding: 5%;
  background-color: var(--white);
  color: black;
  width: 60%;
  height: 75%;
  display: grid;
  grid-template-columns: 1fr;
  margin: auto;
  border-radius: 0.5em;
  justify-content: start;
  align-self: end;

  -webkit-box-shadow: 15px 15px 0px -1px var(--panel-shadow);
}

.reset-password .panel h2 {
  text-align: center;
  color: var(--dark-blue);
  font-size: 2.5em;
  font-weight: bold;
  font-family: var(--main-font);
  width: 75%;
  margin: auto;
}

.reset-password .panel .email-input {
  margin: auto;
  width: 60%;
  display: grid;
}

.reset-password .panel .email-input input {
  width: 100%;
  border: none;
  background-color: #eee;
  border-radius: 0.5em;
  width: 100%;
  height: 75px;
  padding: 5%;
  font-family: var(--main-font);
  font-size: 2em;
}

.reset-password .panel .email-input h3 {
  font-size: 2em;
  font-weight: bold;
  font-family: var(--main-font);
}

.reset-password .reset-button {
  width: 60%;
  margin: 5% auto;
  height: 65%;
  border: none;
  border-radius: 0.5em;
  color: var(--white);
  font-size: 2em;
  font-weight: bold;
  font-family: var(--main-font);
  background-color: var(--purple);
}

@media (max-width: 820px) {
  .reset-password .panel {
    width: 90%;
  }

  .reset-password .panel h2{
    font-size: 2.1rem;
    width: 100%;
  }
  
  .reset-password .panel .email-input {
    width: 90%;
  }

  .reset-password .panel .email-input input {
    font-size: 1.8rem;
  }

  .reset-password .panel .reset-button {
    width: 90%;
  }
}

/* || QUESTS CONTAINER */

.questActivityContainer {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
}


/* || QUESTS MESSAGE */

.quest-message {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
}

.quest-message > p {
  margin: 3%;
}

.toGamePopup{
  position: absolute; 
  width: 100%; 
  height: 115vh; 
  background-color: #000000b8; 
  z-index: 1;
}

.popupBody{
  display: grid;
  margin: auto; 
  width: 60%; 
  height: 50%; 
  justify-self: center; 
  background-color: beige; 
  border-radius: 0.5em;
}
.popupBody p{
  color: black; 
  font-size: 2em; 
  font-weight: bold; 
  text-align: center;
  align-self: center;
  margin: 1%;
}
.popupBody .register-button{
  width: 50%; 
  margin: 2%; 
  height: 50%; 
  place-self: end center;
  align-self: center;
}
.popupBody .register-button.closePopupBtn{
  background-color: #41598E !important;
}
@media (max-width: 1060px){
  .popupBody .register-button{
    width: 55%; 
    margin: 2%; 
    height: 50%; 
    place-self: end center;
    align-self: center;
    font-size: 40px;
  }
}

/* || QUESTS SOURCES */

.quest-source-container {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 20% 80%;
  align-content: center;
  height: 100%;
  margin-bottom: 5%;

  background-size: cover;
  background-color: #FFE7DC;
  max-height: 100%;
  padding: 2%;
}

.white-source-container{
  display: grid;
  grid-template-columns: 1fr 8fr 1fr; 
  grid-template-rows: 1fr; 
  background-color: white; 
  margin: 5% 2% 4% 2%; 
  position: relative;
  height: fit-content;
 /* max-height: 100%; */
}

#source-citation-box{
  background-color: #ebebeb; 
  height: fit-content; 
  color: black;
  margin: 5% auto;
  display: grid;
  grid-template-rows: 1fr auto; 
  width: 100%; 
  border-radius: 0.5em; 
  padding: 1%;
}

.source-title {
  margin-top: 1%;
  font-weight: bold;
  text-align: center;
  font-size: 1.75vi;
}

.source-text {
  white-space: pre-line; /*lets the html recognize \n as a line break. allows the text type sources to be broken up into paragraphs*/
  margin: 2% 0%; 
  max-height: 80%; 
  overflow-y: scroll;
}

.source-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.source-image-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.source-image-block {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.source-image-block img {
  width: 90%;
}

.source-image-container p {
  font-style: italic;
  text-align: center;
  width: 100%;
  font-size: 0.9vi;
  white-space: pre-line;
}

.source-header {
  font-weight: bold;
  font-size: 2vi;
  align-self: self-start;
}

.source-citation{
  white-space: pre-line;
  font-size: 1.1vi;
}

.quest-description{
  white-space: pre-line;
  height: 25vh;
  overflow-y: scroll;
  margin: 0% 1% 2% 1%;
  font-size: 1.25em;
  text-align: center;
  background-color: white;
  border-radius: 0.5em;
  padding: 1%;
  color: black;
  box-shadow: inset 0px 9px 13px -8px rgba(0,0,0,0.5);
  top: 0px;
}

.snapshot-container{
  display: flex ;
  position: absolute; 
  justify-self: end; 
  align-self: end;
  margin-top: 5%; 
  margin-right: 2%; 
  width: auto; 
  cursor: pointer;
}

.snapshot-container p{
  align-self: center; 
  color: black; 
  font-weight: bold; 
  font-size: 1.6vi; 
  margin: 1%; 
  padding: 1%;
  white-space: nowrap;
}

.closePopupBtn{
  color: #eee;
  width: 50%;
  margin: auto;
  justify-self: center;
}

.panel-shadow {
  border-radius: 0.5em;
  -webkit-box-shadow: 15px 15px 0px -1px var(--panel-shadow);
}

.travelogue-shadow {
  border-radius: 25px 0px 0px 25px;
  border-left: 10px solid #5E3116;
  background: linear-gradient(90deg, #873B0C 82.5%, #602A09 100%);
  /*border-radius: 0.5em;
  -webkit-box-shadow: -10px 5px 0px -1px #5E3116;*/
  margin: 12% 2% 0% 2%; 
  display: flex; 
  width: 100%; 
  height: 90%; 
 /* background-color: #873B0C; */

}

.travelogue-textarea-shadow {
  border-radius: 10px 0px 0px 10px;
  /*background: linear-gradient(90deg, #F3EED5 93%, #C7C1A3 96%, #6C6852 100%);*/
  background-color: #F3EED5;

  -webkit-box-shadow: -4px 3px 8.7px 0px rgba(0, 0, 0, 0.38), -8px 1px 0px -1px #C7C1A3, -9px 4px 8.7px 0px rgba(0, 0, 0, 0.38), -15px 2px 0px -1px #C7C1A3, -17px 5px 8.7px 0px rgba(0, 0, 0, 0.38);
  display: flex; 
  margin: auto 5%; 
  margin-right: 0;
  height: 95%; 
  width: 97%; 
  padding: 2%; 
  font-size: 1.3vi; 

}

.travelogue-textarea-shadow > div > textarea {
  background-color: #f6f2c5;
  border: none;
  -webkit-box-shadow: inset 5px 6px 13px -8px rgba(0,0,0,0.5);
  padding: 7px;
  height: 100%;
  color: #4B4B4B;
}

.travelogue-textarea-shadow > div{
  width: -webkit-fill-available; 
  flex-direction: column; 
  display: flex;
}

.studentInput{
  height: 80%; 
  width: 13.5vw;
}

.travelogue-textarea-shadow.three-type{
  margin-right: 80px;
}

.studentInput.three-type{
  margin-right: 2px;
}

.travelogue-textarea-shadow > div > label {
  font-size: 1.5em;
  font-weight: bold;
  color: #515152;
}

.quest-source-container {
  background-size: cover;
  background-color: #FFE7DC;
  max-height: 100%;
  padding: 2%;
}

.quest-source-container > div {
  display: block;
  height: 40em;
  width: 100%;
  text-align: center;
}
.quest-source-container > div > img{
  height: 100%;
  width: auto;
}

.side-arrows {
  height: 3em;
  width: 3em;
  margin: auto;
  cursor: pointer;
  color: rgb(49, 112, 221);
}

.arrows-grey {
  color: rgb(211, 211, 211);
  opacity: 50%;
}

.next-phase {
  position: absolute;
  bottom: 6%;
  right: 5%;
  width: 40%;
  border: none;
  background-color: var(--purple);
  height: 7.5%;
  color: var(--white);
  font-weight: bold;
  font-size: 1.5em;
  border-radius: .5em;
}

.skip-phase {
  position: absolute;
  bottom: 6%;
  right: 50%;
  width: 40%;
  border: none;
  background-color: var(--dark-blue);
  height: 7.5%;
  color: var(--white);
  font-weight: bold;
  font-size: 1.5em;
  border-radius: .5em;
}

.travelogue-tabs {
  position: absolute;
  margin: 15% 3%;
  left: -2%;
}
.travelogue-tabs.three-type-tabs{
  left: 0%;
}

.travelogue-tab {
  font-size: 1.5em;
  font-weight: bold;
  overflow: hidden;
  text-align: center; 
  width: 45px; 
  min-width: 30px !important; 
  height: 69px; 
  background-color: #a41717;
  margin: 10% 0%; 
  display: flex;
  cursor: pointer;
  border-left: 2px solid #7F2121;
  border-radius: 5px 0px 0px 5px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.tab-active {
  width: 45px !important;
}

.travelogue-tab2 {
  font-size: 1.5em;
  font-weight: bold;
  overflow: hidden;
  text-align: center; 
  width: 35px;
  min-width: 30px !important; 
  height: 69px; 
  background-color: #07651c;
  margin: 10% 0%; 
  display: flex;
  cursor: pointer;
  border-left: 2px solid #0c4719;
  border-radius: 5px 0px 0px 5px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.travelogue-tab2:hover {
  margin-left: -5%;
}

#first-phase-text{
  margin: 2%;
  font-size: 1.2em;
  color: #515152;
}

iframe{
  min-height: 45vh;
}

/* || Text to speech (tts) button */
.tts-button{
  position: absolute;
  height: 50px;
  width: 50px;
  background-color:  #fef5f1;
  border: 3px solid var(--panel-background) !important; 
  border-radius: 5px;
  padding: 0 !important;
}

.tts-pause{
  border: none !important;
}

/* || Quest Form */

.formContainer {
  align-content: baseline;
  padding: 5% 0%;
  width: 100%;
  height: 100%;  
  overflow-y: auto;
  background-color: var(--main-background);
}


.formContainer .form {
  border-radius: .5em;
  margin: 1% 5%;
  background-color: var(--white);
  height: 100%;
  /* width: 100%; */
  -webkit-box-shadow: 15px 15px 0px -1px var(--panel-shadow);
}

.form > form {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 10% 10% 3fr 1fr;
}

form > input {
  max-height: 300px;
}

form > textarea {
  max-height: 300px;
}

#phase_container {
  color: black;
}

/*Timeline Ordering*/
.ordering-container{
  background-size: cover; 
  background-color: #FFE7DC !important;
  max-height: 100%;
}

.ordering-box{
  position: relative; 
  z-index: 2;
  contain: content;  
  margin: 5%; 
  display: inline-flex; 
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 60%;
  min-height: 150px;
  min-width: 150px;
  border: 3px solid #000000;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
}

.ordering-box p{
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 0;
  height: auto;
  font-family: var(--header-font);
  font-weight: bold;
  font-size: 2em;
  margin: 1%;
  color: var(--order-number);
  background-color: var(--order-circle);
  padding: .5em 1em;
  border-radius: 100%;
}

.ordering-box img{
  width: 60%;
  margin: auto;
  z-index: 3;
}

.ordering-box-hovered {
  background-color: var(--panel-background);
}

.bank{
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  contain: inline-size;
  width: 25%; 
  height: auto;
  min-height: 200px !important;
  margin: auto;
  border-radius: 8px;
  background: var(--panel-background);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  margin-top: 2px;
}

.bank p{
  margin: 1%;
  font-weight: bold;
  color: black;
  text-align: center;
  background-color: #F4F2F0;
  border-radius: 8%;
  padding: 10%;
}

.bank img{
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.ordering-bank {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  contain: inline-size;
  max-width: 90%; 
  height: auto;
  min-height: 25%;
  margin: auto;
  border-radius: 8px;
  background: #F4F2F0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  font-weight: bold;
  color: black;
}

.item {
  contain: content;
  margin: 2%;
  display: inline-flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--panel-background);
}


.full p {
  overflow-y: auto;
  height: 13rem;
  padding: 1%;
  margin-bottom: 20px;
  scrollbar-color: var(--panel-background);
}

.full h2 {
  color: var(--Theme-1-Black);
  font-size: 1.5rem;
  text-align: center;
  padding: 2%;
  border: 3px, solid, var(--panel-background);
}

.full button{
  font-size: 1.2em;
  font-weight: bold;
  border: 5px solid var(--panel-background);
  padding: 2%;
}

.box {
  position: relative;
  contain: content;
  margin: 5%;
  display: inline-flex;
  flex-flow: wrap;
  height: 80%;
  width: 45%;
  min-height: 150px;
  border: dashed 3px var(--panel-background);
}

.matching {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1%;
  width: 70%;
  max-height: 100%;
  padding-left: 1%;
  background-color: var(--panel-shadow);
  color: var(--Theme-1-Black);
}

.matching > div, .matching > div > div{
  display: flex;
}

.matching > div > div{
  padding-top: 13%;
}

.matchingText {
  width: 55%;
  text-align: center;
  background-color: #F9FAFF;
  border: 3px solid var(--panel-background);
}

.content-and-bank {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 1%;
  background-color: var(--panel-shadow);
}
/* Approval Page and Travelouge Teacher view page*/
.studentView.TravelogueView{
  min-height: 100vh;
}
.studentView .headerRow {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: fit-content;
}
h1.Approval-Travelogue-Header{
  text-align: center;
  font-family: var(--header-font);
  color: var(--dark-purple);
  font-weight: bold;
  font-size: 3em;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 2%;
}

h2.Approval-Travelogue-Header{
  text-align: center;
  color:  var(--Theme-1-Black, #212022);
  font-family: var(--header-font);
}

.studentView .studentContainer{
  padding: 2%;
  color: var(--Theme-1-Black);
}

.Quest{
  display: block;
  align-items: center;
}
.Phase{
  padding-left: 2%;
}
.dropdown{
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: var(--header-font);
}
.Quest h1{
  display: inline;
  color: var(--dark-purple);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Quest .material-symbols-outlined{
  display: inline;
  text-shadow: none;
  font-weight: 600;
  font-size: 2em;
  align-items: center;
}
.Phase h2{
  display: inline;
}
.phaseContent{
  padding-left: 2%;
  font-family:  "Quicksand", sans-serif;
}
.phaseContent h3{
  font-family: var(--header-font);
  font-size: 1.5em;
}
.phaseContent p{
  white-space: pre-line;
}



